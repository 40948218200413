.inp-data{
    display: block;
    width: 100%;
    height: 40px;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #000;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #dbdbdb;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.inp-data:disabled {
    background-color: #E8E8E8;
    border: 1px solid #D1D3D4;
}