

.swal2-styled.swal2-cancel {
    border: 0;
    border-radius: 0.25em;
    background: initial;
    background-color: #6e7881;
    color: #fff;
    font-size: 0.9rem;
    font-size: clamp(0.9rem, 1.5vw, 0.9rem);
    height: 44px;
    width: 65px;
}

.swal2-title {
    font-family: 'db_heaventregular', sans-serif !important;
}

.swal2-styled {
    font-family: 'db_heaventregular', sans-serif !important;
    font-size: 0.9rem;
    font-size: clamp(0.7rem, 1.5vw, 0.9rem);
}