.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: white !important;
    background-color: var(--tabMenuColor) !important;
}
.sidebar {
    background:  var(--sidebarColor) !important;
}
.nav-pills .nav-link:not(.active):hover {
    /* color: #55C091;*/
    /* color: var(--tabMenuActionColor) !important; */
  }
  .headermenu {
    cursor: pointer;
    /* color: var(--textColor) !important; */
  }
  .headermenu-active{
    cursor: pointer;
    color:white !important;
  }
  .nav-sidebar .nav-item>.nav-link {
    position: relative;
    color: var(--textColorActive) !important;
}
/* new  */
.bg-primary{
  background: var(--bannerBgColor) !important;
  /* #00A79D !important */
}