.my-swal-popup {
    max-width: 425px;
    border-radius: 12px !important;
    /* border-top: 5px solid var(--custom-color);  */
    font-weight: bold;
}
.my-swal-popup #swal2-html-container {
    font-weight: bold;
}

.my-swal-popup button {
    min-width: 100px !important;
    border-radius: 8px !important;
}
.my-swal-popup .swal2-cancel  {
    border: 1px solid #164082 !important;
    color: #164082 !important;
}
.swal2-styled.swal2-confirm {
  width: 80px;
}
.swal2-styled.swal2-confirm:focus {
  box-shadow: none;
}

