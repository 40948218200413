*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  margin: 0px;
  padding: 0px;
  background-color: #f4f6f9;
  overflow-x: hidden;
  font-family: 'db_heaventregular', sans-serif !important;
}

.base {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  scroll-behavior: smooth;
  overflow-x: visible;
  background-color: #12349f !important;
}

.base button {
  background-color: #12349f !important;
}

body .swal2-container {
  white-space: pre-line;
}

.layout-footer-fixed .wrapper .content-wrapper {
  padding-bottom: calc(5rem + 1px) !important;
}

.area100 {
  width: 100%;
}

.colorGray01 {
  color: #3B4752;
}

.colorGray02 {
  color: #939598;
}

.colorBlue01 {
  color: #12349F;
}

.colorBlue02 {
  color: #657BB1;
}

.colorBlue03 {
  color: #6790FF;
}

.colorWhite {
  color: white;
}

.colorRed {
  color: #eb0019;
}

.colorGreen01 {
  color: #00A79D;
}

.colorGreen02 {
  color: #90CD8D;
}

.colorYellow01 {
  color: #FDDC00;
}

.colorViolet01 {
  color: #C298FF;
}

.colorViolet02 {
  color: #5C1B6C;
}

.colorPink01 {
  color: #FF90AB;
}



/* .box-white {
  background-color: white;
  border: none;
  margin: 6px;
  box-shadow: 5px 10px 25px #dbdbdb;
  align-self: center;
} */



.line-height01 {
  line-height: 1.8;
}

.line-height02 {
  line-height: 1.5;
}

.line-height03 {
  line-height: 1;
}

.line-height04 {
  line-height: 2;
}

.fontReg {
  font-family: 'db_heaventregular', sans-serif !important;

}

.font-table-style-custom {
  font-family: 'db_heaventregular', sans-serif !important;
  font-size: 12px !important;
  white-space: nowrap !important;

}

.fontLight {
  font-family: 'db_heaventregular', sans-serif !important;
}

.font-size01 {
  font-size: 1rem !important;
  font-size: clamp(0.9rem, 1.5vw, 1rem) !important;
}

.font-size02 {
  font-size: 1.1rem !important;
  font-size: clamp(0.9rem, 1.5vw, 1.1rem) !important;
}

.font-size03 {
  font-size: 0.9rem !important;
  font-size: clamp(0.7rem, 1.5vw, 0.9rem) !important;
}

.font-size04 {
  font-size: 2rem !important;
  font-size: clamp(1.1rem, 2.5vw, 2rem) !important;
}

.font-size05 {
  font-size: 1.5rem !important;
  font-size: clamp(0.7rem, 2.5vw, 1.5rem) !important;
}

.im-tiltle {
  width: 50px;
  height: auto;
  object-fit: contain;
  margin-right: 15px;
}

.ic-files {
  width: 20px;
  height: auto;
  object-fit: contain;
  margin-right: 10px;
}


.ic-search {
  width: 15px;
  height: auto;
  object-fit: contain;
  margin-right: 5px;
}

.ic-Recommend {
  width: 22px;
  height: auto;
  object-fit: contain;
  margin-right: 5px;
}


.btn-expFile {
  background-color: #fff;
  border: 1px solid #D1D3D4;
  border-radius: 5px;
  width: 30%;
  height: 40px;
  padding: 0 10px;
  cursor: pointer;
}

.btn-expFile:hover {
  background-color: #e1e1e1;
}

.btn-secondary {
  color: rgb(0, 0, 0);
  background-color: #D1D3D4;
  border: 1px solid #D1D3D4;
  box-shadow: none;
}

.btn-secondary:hover {
  background-color: #bcbcbc;
  border: 1px solid #D1D3D4;
  color: rgb(0, 0, 0);
  outline: none;
}

.card-white {
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: white;
  border-radius: 15px;
  border: none;
}

.im-slip {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.box-process {
  border-radius: 50px;
  background-color: #FA9349;
  padding: 10px 20px;
  color: #fff;
}

.box-paid {
  border-radius: 50px;
  background-color: #87BD66;
  padding: 10px 20px;
  color: #fff;
}

.box-fail {
  border-radius: 50px;
  background-color: #D95350;
  padding: 10px 20px;
  color: #fff;
}

.border-left01 {
  border-left: 5px solid #06BBFF;
  margin-right: 5px;
}

.border-right01 {
  border-right: 1px solid #D1D3D4;
}

.border-right02 {
  border-right: 1px solid #D1D3D4;
}

:focus-visible {
  outline: -webkit-focus-ring-color auto 0;
}

.card-body {
  padding: 1rem !important;
}



.im-sports-type {
  width: 40%;
  height: auto;
  object-fit: contain;
}

.im-sports-type-dt {
  width: 30%;
  height: auto;
  object-fit: contain;
}

.ic-actions {
  width: 15px;
  height: auto;
  object-fit: contain;
}

.ic-edit {
  width: 15px;
  height: auto;
  object-fit: contain;
}

.im-logo {
  width: 300px;
  height: auto;
  object-fit: contain;
  z-index: 2;
}

.h-100vh {
  height: calc(100vw * (9/16));
  max-height: calc(30em * (9/16));
}

.btn-cancel {
  background-color: #D1D3D4;
  border: 1px solid #D1D3D4;
  border-radius: 5px;
  width: 100%;
  height: 40px;
  padding: 0 5px;
  cursor: pointer;
  color: #3B4752;
}

.btn-cancel:hover {
  background-color: #bcbcbc;
}

.btn-save {
  background-color: #0698FF;
  border: 1px solid #0698FF;
  border-radius: 5px;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  cursor: pointer;
  color: white;
  margin-left: 10px;
}

.btn-save:hover {
  background-color: #0081dd;
}


.drpdw-style {
  position: relative;
  width: 100%;
  height: auto;
  padding: 5px 10px;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #D1D3D4;
}

.headermenu {
  cursor: pointer;
}

.inp-area {
  display: block;
  width: 100%;
  height: 100%;
  padding: 15px 6px;
  font-family: 'db_heaventregular', sans-serif !important;
  line-height: 1.5;
  font-size: 0.9rem;
  font-size: clamp(0.7rem, 1.5vw, 0.9rem);
  font-weight: 400;
  color: #000000;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.im-profile {
  width: 145px;
  height: auto;
  object-fit: contain;
}

.brand-link {
  padding: 0 !important;
}

.brand-image {
  width: 100% !important;
  max-height: 80px !important;
  margin-right: 0 !important;
  margin-top: 0 !important;
}

.bdl-yellow01 {
  border-left: 5px solid #FDDC00;
}

.main-sidebar {
  overflow-y: visible !important;
}

.pdb-page {
  padding-bottom: 80px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0 !important;
  content: "";
  border-top: none !important;
  border-right: none !important;
  border-bottom: none !important;
  border-left: none !important;
}

@media only screen and (max-width : 1440px) {

  table.dataTable thead>tr>th.sorting,
  table.dataTable thead>tr>th.sorting_asc,
  table.dataTable thead>tr>th.sorting_desc,
  table.dataTable thead>tr>th.sorting_asc_disabled,
  table.dataTable thead>tr>th.sorting_desc_disabled,
  table.dataTable thead>tr>td.sorting,
  table.dataTable thead>tr>td.sorting_asc,
  table.dataTable thead>tr>td.sorting_desc,
  table.dataTable thead>tr>td.sorting_asc_disabled,
  table.dataTable thead>tr>td.sorting_desc_disabled {
    padding-right: 26px !important;
  }
}

@media only screen and (max-width : 1199px) {
  .mgt-upload {
    margin-top: 32px !important;
  }

  .mgt-head-mob {
    margin-top: 1rem !important;
  }
}

@media only screen and (max-width : 1060px) {

  .btn-expFile,
  .btn-blue02 {
    padding: 0 3px;
  }

  .ic-add {
    width: 10px;
  }

  table.dataTable thead>tr>th.sorting,
  table.dataTable thead>tr>th.sorting_asc,
  table.dataTable thead>tr>th.sorting_desc,
  table.dataTable thead>tr>th.sorting_asc_disabled,
  table.dataTable thead>tr>th.sorting_desc_disabled,
  table.dataTable thead>tr>td.sorting,
  table.dataTable thead>tr>td.sorting_asc,
  table.dataTable thead>tr>td.sorting_desc,
  table.dataTable thead>tr>td.sorting_asc_disabled,
  table.dataTable thead>tr>td.sorting_desc_disabled {
    padding-right: 25px !important;
  }
}

@media only screen and (max-width : 992px) {

  .ic-files,
  .ic-add {
    margin-right: 0;
  }

  .dataTables_wrapper {
    overflow-x: scroll;
  }

  .rotate0 {
    transform: rotate(0deg) !important;
  }

  .rotate-90 {
    transform: rotate(-90deg) !important;
  }

  .showMenu {
    display: block !important;
  }

  .hideMenu {
    display: none !important;
  }

  .ic-search {
    margin-right: 0px;
  }

  .mgt-mob {
    margin-top: 5px !important;
  }
}

@media only screen and (max-width : 600px) {
  .im-tiltle {
    width: 35px;
  }

  .btn-expFile,
  .btn-blue02 {
    height: 30px;
  }

  .ic-files {
    width: 15px;
  }

  .btn-green01 {
    height: 45px;
    padding: 10px;
  }
}

@media only screen and (max-width : 575px) {
  .btn-blue05 {
    height: 30px;
  }

  .ic-search {
    margin-right: 5px;
  }

  .im-logo {
    width: 100%;
  }
}

@media only screen and (max-width : 375px) {
  .card-white {
    padding: 10px 5px;
  }

  .btn-search,
  .btn-reset {
    width: 100px;
  }
}

/* btn */

button:disabled,
button[disabled] {
  /* pointer-events: none !important; */
  opacity: 0.65 !important;
}

.col-1-5 {
  width: 14% !important;
  padding: 3px;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


.pagination {
  justify-content: end !important;
}


.pagination li.active button.pageNum {
  /* Your active page button styles */
  background-color: #007BFF;
  color: #fff;
  border: 1px solid #007BFF;
  /* Add more styles as needed */
}

.pagination li button.prev {
  border: 0px solid #D1D3D4;
  border-radius: 5px 0 0 5px;
  padding: 7px 10px;
  background-color: transparent;
}

.pagination li button.next {
  border: 0px solid #D1D3D4;
  border-radius: 0 5px 5px 0;
  padding: 7px 10px;
  background-color: transparent;
}

.pagination li button.pageNum {
  border: 1px solid #D1D3D4;
  border-radius: 0;
  padding: 5px 10px;
  background-color: transparent;
}

.col-20 {
  width: 20% !important;
}


.mbs-4 {
  margin-bottom: 4px;
}

.mts-5 {
  margin-top: 5px;
}

.mg-card-user {
  margin: 5px 0 15px 0;
}

.mg-3-style {
  margin: 3px;
}

.ml-4-style {
  margin-left: 4px !important;
}


/* @media screen and (min-width: 1000px) and (max-width: 1500px){
   .inp-textarea {
  background-color: #E8E8E8;
  border: 1px solid #D1D3D4;
  resize: none !important;
  min-width: 18rem  !important;
}
} */
.inp-textarea {
  display: block;
  /* width: 100%;
  height: 40px; */
  padding: 0.375rem 0.75rem !important;
  line-height: 1.5;
  color: #000;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dbdbdb;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  resize: none !important;
}

.inp-textarea:disabled {
  background-color: #E8E8E8;
  border: 1px solid #D1D3D4;
  resize: none !important;
}

/* --------- */
.accordion-button:not(.collapsed)::after {
  /* background-image: var(--bs-accordion-btn-active-icon); */
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)) !important
  ; */
  /* background-image: url("../public/assets/icons/ic-calendar3.png") !important; */
  /* content: "\f007" !important; */
  /* transform: var(--bs-accordion-btn-icon-transform); */
  font-size: 50px !important;
}

/* --------- */
.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
  border-radius: 1px !important;
}

.accordion-button:not(.collapsed) {
  color: black !important;
  background-color: white !important;
  /* border-top-left-radius: 10px !important;  
  border-top-right-radius: 10px !important; */
  /* 
 
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color); */
  /* border-top-left-radius: 10px !important;  
  border-top-right-radius: 10px !important; */
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 2px;
  border: 1px solid #D1D3D4 !important;
  /* border-radius: 10px ; */
}

.card-term {
  width: 970px !important;
  background-color: #E8E8E8 !important;
  border: 1px solid #D1D3D4 !important;
  box-shadow: 0 0 0px rgba(0, 0, 0, .125), 0 0px 0px rgba(0, 0, 0, .2) !important;

  /* display: none !important; */
}

.view-edit-term {
  margin: -33px 0 0 48px !important;
}

@media screen and (min-width: 1000px) and (max-width: 1400px) {
  .view-edit-term {
    margin: -33px 0 0 36px !important;
  }
}

.text-dl {
  text-decoration-line: none !important;
}

.ml-5-style {
  margin-left: -5px;
}

.mt-45-style {
  margin-top: 45px !important;
}

.eye-password-style {
  margin-top: -38px !important;
  margin-right: 9px !important;
}

.guihQj {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 2px dashed rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s ease 0s;
  cursor: pointer;
  height: 51px !important;
}

.tooltip-custom-font {
  font-size: 12px !important;
  /* Adjust the font size to your preference */
}

.card.card-term.view-edit-term {
  width: 100% !important;
}

/* new */
.box-white {
  background-color: white;
  border: none;
  padding: 20px;
  margin: 15px;
  box-shadow: 5px 10px 25px #dbdbdb;
  border-radius: 15px;
}

.ic-add {
  width: 10px;
  height: auto;
  object-fit: contain;
  margin-right: 4px;
}

.navi-color {
  color: black;
}