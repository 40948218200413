* ::after ::before {
    box-sizing: border-box;
    margin: 0;
}

#login {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    scroll-behavior: smooth;
    overflow-x: visible;
    background-color: #12349f !important;
}

.height100 {
    height: 100vh;
}

.area01 {
    width: 75%;
}

.im-login01 {
    width: 450px;
    height: auto;
    object-fit: contain;
    z-index: 2;
}

.im-login02 {
    position: absolute;
    z-index: 3;
    width: 200px;
    top: 15px;
    left: 37%;
    transform: translate(-50%, 0);
}

.im-login03 {
    position: absolute;
    z-index: 3;
    width: 200px;
    top: 15px;
    left: 14%;
}

.im-NWlogo {
    width: 120px;
    height: auto;
    object-fit: contain;
}

.line-bottom {
    border-bottom: 1px solid #A7A9AC;
}

.inp-login {
    display: block;
    width: 100%;
    height: 40px;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #000;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #dbdbdb;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.inp-login:disabled {
    background-color: #E8E8E8;
    border: 1px solid #D1D3D4;
}

/* The container */
.checkbox-item01 {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-item01 input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 17px;
    width: 17px;
    background-color: #fff;
    border: 1px solid #A7A9AC;
    border-radius: 3px;
}



.btn-green {
    display: block !important;
    width: 100% !important;
    height: 35px !important;
    padding: 0.375rem 0.75rem !important;
    line-height: 1.5 !important;
    color: #fff !important;
    background-color: #00A79D !important;
    background-clip: padding-box !important;
    border: 1px solid #00A79D !important;
    border-radius: 0.25rem !important;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;
    cursor: pointer !important;
    margin-bottom: 20px !important;
    text-align: center !important;
}

.btn-green:hover {
    color: #fff;
    background-color: #00A79D;
}

.sty-box01 {
    margin: auto;
    width: 70%;
}

.flying-item {
    animation: wave 10s linear infinite;
    -webkit-animation: wave 10s linear infinite;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}

.box-pass {
    display: block;
    width: 100%;
    padding: 0 0.75rem;
    line-height: 1.5;
    color: #000;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #A7A9AC;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    height: 40px;
}

.inp-pass {
    border: none;
    padding: 0;
    height: 40px;
    width: 100%;
    background-color: transparent;

}

@keyframes wave {
    0% {
        transform: translateX(0) translateZ(0) scaleY(1)
    }

    50% {
        transform: translateX(-25%) translateZ(0) scaleY(0.55)
    }

    100% {
        transform: translateX(-50%) translateZ(0) scaleY(1)
    }
}

.bouncing-item {
    color: #3079ed;
    animation-name: angry-animation;
    animation-duration: 10s;
    animation-timing-function: ease;
    animation-delay: 0.2s;
    animation-iteration-count: infinite;
    animation-direction: normal;
}

@keyframes angry-animation {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    10% {
        -webkit-transform: translateY(-6px);
        transform: translateY(-6px);
    }

    20% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    30% {
        -webkit-transform: translateY(-6px);
        transform: translateY(-6px);
    }

    40% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    50% {
        -webkit-transform: translateY(-6px);
        transform: translateY(-6px);
    }

    60% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    70% {
        -webkit-transform: translateY(-6px);
        transform: translateY(-6px);
    }

    80% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    90% {
        -webkit-transform: translateY(-6px);
        transform: translateY(-6px);
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@media only screen and (max-width : 992px) {
    .login02 {
        margin-top: -1px;
    }

    .im-NWlogo {
        margin-top: 20px;
    }
}

@media only screen and (max-width : 1375px) {
    .area01 {
        width: 100%;
    }
}

@media only screen and (max-width : 500px) {
    .im-login01 {
        width: 274px;
    }

    .im-login02,
    .im-login03 {
        width: 116px;
    }
}

@media only screen and (max-width : 380px) {
    .sty-box01 {
        width: 83%;
    }
}

.text-title {
    text-shadow: -1px -1px transparent, 2px 2px rgba(255, 255, 255, 0.5);
    color: #FFFFFF;
}