.login-template-color {
    background-color: #281C9D;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
}
.login-template-text {
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
}