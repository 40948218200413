.dot-success {
    height: 15px;
    width: 15px;
    background-color: #8DC63F;
    border-radius: 50%;
    display: inline-flex;
    margin: 0px 0 -2px 12px;
  }
  .dot-danger {
    height: 15px;
    width: 15px;
    background-color: #C63F3F;
    border-radius: 50%;
    display: inline-flex;
    margin: 0px 0 -2px 12px;
  }