/* test grid */
.parent {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    }
    
    .div-left-card { grid-area: 1 / 1 / 4 / 3; }
    .div-right-card { grid-area: 1 / 3 / 4 / 6; }

    