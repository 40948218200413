.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: var(--bs-progress-bar-color);
    text-align: center;
    white-space: nowrap;
    background-color: rgba(85, 192, 145, 1) !important;
    transition: var(--bs-progress-bar-transition);
}
.progress, .progress-stacked {
    box-shadow: 2px 2px 2px 0px #F4F4F4 !important;
    --bs-progress-height: 1.5rem !important;
    border-radius: 12px !important;
}