.box-headText {
    background-color: var(--headSecondColor);
    border-left: 6px solid var(--headPrimaryColor) !important;
    padding: 10px;
    height: 0;
    margin: 0 15px;
  }

  .navbar-Template {
    background-color:var(--mainHeaderbg);
    color: #1f2d3d;
    height: 55px !important;
}
.img-title-header{
  width: 40px !important;
}