table {
    border-collapse: collapse;
    border: 1px solid #ddd;
    /* #ddd; */
  }

  .table td, .table th {
    border: 1px solid  var(--tbBorderColor) ;
    /* #dee2e6 !important; */
}
  
  .table thead th {
    
    background-color:  var(--thColor) ;
     /* #f3f3f3 !important; */
  }
  
  .table th {
    vertical-align: middle !important;
    text-align: center;
  }
  
  .table td {
    vertical-align: middle !important;
    text-align: center;
    background:var(--tdColor) !important;
  }
  
  table.dataTable thead th, table.dataTable thead td, table.dataTable tfoot th, table.dataTable tfoot td {
    text-align: center !important;
  }
  
  table.dataTable thead>tr>th.sorting, table.dataTable thead>tr>th.sorting_asc, table.dataTable thead>tr>th.sorting_desc, table.dataTable thead>tr>th.sorting_asc_disabled, table.dataTable thead>tr>th.sorting_desc_disabled, table.dataTable thead>tr>td.sorting, table.dataTable thead>tr>td.sorting_asc, table.dataTable thead>tr>td.sorting_desc, table.dataTable thead>tr>td.sorting_asc_disabled, table.dataTable thead>tr>td.sorting_desc_disabled {
    padding-right: 15px !important;
  }
  
  .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    color: white !important;
    border: none !important;
    background-color: transparent !important;
  }