.btn-login {
  display: block !important;
  width: 100% !important;
  height: 35px !important;
  padding: 0.375rem 0.75rem !important;
  line-height: 1.5 !important;
  color: #fff !important;
  background-color: var(--colorLoginBtn) !important;
  background-clip: padding-box !important;
  border: 1px solid var(--colorLoginBtn) !important;
  border-radius: 0.25rem !important;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out !important;
  cursor: pointer !important;
  margin-bottom: 20px !important;
  text-align: center !important;
}

.btn-login:hover {
  color: #fff;
  background-color: var(--hoverLoginBtn) !important;
}
.btn-view {
    background-color:var(--colorViewBtn) !important;
    border: 1px solid var(--colorViewBtn) !important;
    border-radius: 5px;
    width: 35px;
    height: 35px;
    cursor: pointer;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn-view:hover {
    background-color: var(--hoverViewBtn) !important;
  }
  .btn-edit {
    background-color: var(--colorEditBtn) !important;
    border: 1px solid  var(--colorEditBtn) !important;
    border-radius: 5px;
    width: 35px;
    height: 35px;
    cursor: pointer;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn-edit:hover {
    background-color:  var(--hoverEditBtn) !important;
  }


.btn-remove {
    background-color: var(--colorDeleteBtn) !important;
    border: 1px solid var(--colorDeleteBtn) !important;
    border-radius: 5px;
    width: 35px;
    height: 35px;
    cursor: pointer;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn-remove:hover {
    background-color: var(--hoverDeleteBtn) !important;
  }
  .btn-create {
    width: 87px;
    /* colorCreateBtn */
    background: var(--colorCreateBtn) !important;
    border: none;
    border-radius: 6px;
    padding: 10px 15px;
    color: white;
     font-family: 'db_heaventregular', sans-serif !important;
    font-size: clamp(0.7rem, 1.5vw, 0.9rem);
    white-space: nowrap !important;
  }
  .btn-create:hover {
    background-color: var(--hoverCreateBtn) !important;
  }

  .btn-search {
    width: 130px;
    background-color: var(--colorSearchBtn) !important;
    border: none;
    border-radius: 6px;
    padding: 10px 15px;
    color: white;
    font-family: 'db_heaventregular', sans-serif !important;
    font-size: clamp(0.7rem, 1.5vw, 0.9rem);   
    align-items: center;
    justify-content: center;
  }
  .btn-search:hover {
    background-color: var(--hoverSearchBtn) !important;
  }
  .btn-delete {
    /*  */
    white-space: nowrap;
    width: 130px;
    background-color: var(--colorDeleteBtn) !important;
    border: 1px solid var(--colorDeleteBtn) !important;
    /* border: none; */
    border-radius: 6px;
    /* padding: 10px 15px; */
    color: white;
    font-family: 'db_heaventregular', sans-serif !important;
    font-size:12px !important;
  }
  .btn-delete:hover {
    background-color: var(--hoverDeleteBtn) !important;
  }
  .btn-reset {
    width: 130px;
    background-color: var(--colorResetBtn) !important;
    border: none;
    border-radius: 6px;
    padding: 10px 15px;
    color: white;
    font-family: 'db_heaventregular', sans-serif !important;
    font-size: clamp(0.7rem, 1.5vw, 0.9rem);
  }
  .btn-reset:hover {
    background-color:  var(--hoverResetBtn) !important;
  }

  .btn-save {
    width: 120px;
    /* background-color: #00A65A; */
    background-color:  var(--colorSaveBtn) !important;
    border: none;
    border-radius: 6px;
    /* padding: 10px 15px; */
    color: white;
    font-family: 'db_heaventregular', sans-serif !important;
    /* font-size: clamp(0.7rem, 1.5vw, 0.9rem); */
  }
  .btn-save:hover {
    /* background-color: #008549;
     */
     background-color:  var(--hoverSaveBtn) !important;
  }
  
  .btn-cancle {
    width: 120px;
    /* background-color: #ffffff; */
    background-color:  var(--colorCancleBtn) !important;
    border: 1px solid #dbdbdb;
    border-radius: 6px;
    /* padding: 10px 15px; */
    color: #000000;
    font-family: 'db_heaventregular', sans-serif !important;
    /* font-size: clamp(0.7rem, 1.5vw, 0.9rem); */
  }
  .btn-cancle:hover {
    /* background-color: #d4d4d4; */
    background-color:  var(--hoverCancleBtn) !important;
  }
  .btn-upload{
    width: 120px;
    /* background-color: #00A65A; */
    background-color:  var(--colorUploadBtn) !important;
    border: none;
    border-radius: 6px;
    /* padding: 10px 15px; */
    color: white;
    font-family: 'db_heaventregular', sans-serif !important;
    /* font-size: clamp(0.7rem, 1.5vw, 0.9rem); */
  }
  .btn-upload:hover {
    /* background-color: #008549;
     */
     background-color:  var(--hoverUploadBtn) !important;
  }
  .btn-export {
    /* width: 150px; */
    background-color: var(--colortExpBtn) !important;
    /* border: none; */
    border-radius: 6px;
    padding: 10px 15px;
    /* colortextExpBtnExcel */
    border: 1px solid var(--colorBorderExp) !important;
    font-family: 'db_heaventregular', sans-serif !important;
    font-size: clamp(0.7rem, 1.5vw, 0.9rem);
    white-space: nowrap !important;
}
.btn-export:hover {
  border: 1px solid var(--colorBorderExpBtn) !important;
  background-color:  var(--hoverExpBtn) !important;
  }

  .btn-import {
    /* width: 150px; */
    background-color: var(--colortImtBtn) !important;
    /* border: none; */
    border-radius: 6px;
    padding: 10px 15px;
    /* colortextExpBtnExcel */
    border: 1px solid var(--colorBorderExp) !important;
    font-family: 'db_heaventregular', sans-serif !important;
    font-size: clamp(0.7rem, 1.5vw, 0.9rem);
    white-space: nowrap !important;
}
.btn-import:hover {
  border: 1px solid var(--colorBorImtBtn) !important;
  background-color:  var(--hoverBorBtn) !important;
  }
  .btn-create {
    /* width: 150px; */
    background-color: var(--colortCreBtn) !important;
    /* border: none; */
    border-radius: 6px;
    padding: 10px 15px;
    /* colortextExpBtnExcel */
    border: 1px solid var(--colorBorCreBtn) !important;
    font-family: 'db_heaventregular', sans-serif !important;
    font-size: clamp(0.7rem, 1.5vw, 0.9rem);
    white-space: nowrap !important;
}
.btn-create:hover {
  border: 1px solid var(--colorBorCreBtn) !important;
  background-color:  var(--hoverCreBtn) !important;
  }
.btn-verify {
    background-color: var(--colorVerifyBtn) !important;
    border: 1px solid  var(--colorVerifyBtn) !important;
    border-radius: 5px;
    width: 135px;
    height: 68px !important;
    cursor: pointer;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
 .btn-verify:hover {
    background-color:  var(--hoverVerifyBtn) !important;
  }
  .btn-download {
   /* width: 150px; */
   background-color: var(--colorPrimary) !important;
   /* border: none; */
   border-radius: 15px;
   padding: 10px 15px;
   /* colortextExpBtnExcel */
   border: 1px solid var(--colorPrimary) !important;
   color:white !important;
   font-family: 'db_heaventregular', sans-serif !important;
   font-size: clamp(0.7rem, 1.5vw, 0.9rem);
  }
 .btn-download:hover {
  border: 1px solid var(--colorPrimaryhover) !important;
  background-color:  var(--colorPrimaryhover) !important;
  }.btn-file {
    background-color: var(--colorVerifyBtn) !important;
    border: 1px solid  var(--colorVerifyBtn) !important;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
 .btn-file:hover {
    background-color:  var(--hoverVerifyBtn) !important;
  }
  .btn-edit {
    /*  */
    white-space: nowrap;
    width: 130px;
    background-color: var(--colorEditBtns) !important;
    border: 1px solid var(--colorEditBtns) !important;
    /* border: none; */
    border-radius: 6px;
    /* padding: 10px 15px; */
    color: white;
    font-family: 'db_heaventregular', sans-serif !important;
    font-size:12px !important;
  }
  .btn-edit:hover {
    background-color: var(--hoverEditBtns) !important;
  }
 