div:where(.swal2-container) h2:where(.swal2-title) {
    font-size: 1.875em;
    font-family: 'db_heaventregular', sans-serif !important;
}
.swal2-styled.swal2-cancel {
    border: 0;
    border-radius: 0.25em;
    background: initial;
    background-color: #6e7881;
    color: #fff;
    font-size: 1em;
    width: max-content !important;
    font-family: 'db_heaventregular', sans-serif !important;
}
.swal2-styled.swal2-confirm {
    border: 0;
    border-radius: 0.25em;
    background: initial;
    background-color: #0698ff;
    color: #fff;
    font-size: 1em;
    font-family: 'db_heaventregular', sans-serif !important;
}
div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm:focus {
    box-shadow: 0 0 0 3px rgba(6,152,255,.5);
}