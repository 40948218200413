.custom-input-date {
    /* background: url("../../../public/assets/icons/ic-calendar.png") no-repeat right;
    background-size: 20px; */
    background: url("../../../public/assets/icons/ic-calendar3.png") no-repeat right;
    background-size: 34px;
    background-color: white;
    border: 1px solid #D1D3D4;
    border-radius: 5px;
    width: 100%;
    height: 41px;
    padding: 0 100px 0 10px ;
    /* padding: 0 70px 0 10px ; */
    font-family: 'db_heaventregular', sans-serif !important;
    font-size: 0.9rem;
    font-size: clamp(0.7rem, 1.5vw, 0.9rem);
}

.custom-input-date:focus-visible {
    outline: none;
}
