.upload {
    display: flex;
    position: relative;
}

.thumbsContainer {
    display: flex;
    margin-top: 0;
    width: 100%;
    flex-wrap: wrap;
}

.countimg {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: -22px;
    color: #55C091;
}

.remark-dt {
    position: absolute;
    top: 0;
    /* left: 0; */
    /* left: 72px; */
    /* margin-top: -30px; */
    margin-top: 54px;
    width: auto;
}

.btn-uploadimg{
   background-color:#03A9F4 !important ;  
   /* width: 120px; */
   border: none;
   border-radius: 6px;
   color: white;
}
.card-upload {
    background-color: #fff;
    color: #3B4752;
    font-weight: 500;
    border: 1px dashed #D1D3D4;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    /* margin-left: 21px; */
}
.card-upload-banner {
    background-color: #fff;
    color: #3B4752;
    font-weight: 500;
    border: 1px dashed #D1D3D4;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: inherit;
    height: 50px;
    margin-left: 0;
}
.juvIAA {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    /* height: auto !important; */
    border-radius: 4px;
    border: 2px dashed rgba(0, 0, 0, 0.3);
    transition: background-color 0.3s ease;
    cursor: pointer;
    padding: 7px !important;
    height: 69px !important;
    
}
.btn-upload {
    background-color: var(--colorSearchBtn) !important;
    border: 1px solid  var(--colorSearchBtn) !important;
    border-radius: 5px;
    width: 120px !important;
    height: 32px !important;
    padding: 0 10px;
    cursor: pointer;
    color: white;
    margin-left: 10px;
}
.btn-upload:hover {
    background-color: var(--hoverSearchBtn) !important;
}

.thumb {
    display: inline-flex;
    border-radius: 0px;
    margin-bottom: 20px;
    margin-left: 0px;
    /* width: auto; */
    width: inherit;
    height: 70px;
    box-sizing: border-box;
    position: relative;
}

.boxthumb {
    position: relative;
    display: inline-flex;
    /* width: 100%; */
    width: 50px;
    height: 50px;
    margin-left: -5px;
    border: 1px dashed rgb(209, 211, 212);
    margin-bottom: 20px;
}
.boxthumb-banner {
    position: relative;
    display: inline-flex;
    /* width: auto; */
    width: 110px;
    height: 50px;
    margin-left: 5px;
    border: 1px dashed rgb(209, 211, 212);
    margin-bottom: 20px;
}
.remove-img {
    background-color: #be1e2d;
    border: 1px solid #be1e2d;
    border-radius: 50px;
    height: 25px;
    margin-right: -10px;
    margin-top: -10px;
    position: absolute;
    right: 0;
    top: 0;
    width: 25px;
}
.remove-img-banner {
    background-color: #be1e2d;
    border: 1px solid #be1e2d;
    border-radius: 50px;
    height: 25px;
    margin-right: -10px;
    margin-top: -3px;
    position: inherit;
    top: 0;
    width: 25px;
    right: 12px;
}
.ic-remove-img {
    width: 10px;
    height: auto;
    object-fit: contain;
    transform: rotate(45deg);
}

.text-white {
    color: #fff !important;
}

@media only screen and (max-width : 767px) { 
    .thumb, .boxthumb {
        margin-left: 0;
        margin-right: 15px;
    }
    .remark-dt {
        margin-top: -25px;
    }
}
.remark-dt-banner {
    position: absolute;
    top: 0;
    /* left: 0; */
    left: 21px;
    /* margin-top: -30px; */
    margin-top: 57px;
    width: auto;
}
.remark-dt-banner-position {
    left: 4px !important;
    margin-top: 40px !important;
}
.remark-delete-banner-position {
    left: -4px !important;
    margin-top: 16px !important;
}
.update-banner-img {
    left: 8px !important;
    margin-top: 35px !important;
}
.img-w{
    /* width: 60px;  */
    margin-left: -13px;
}