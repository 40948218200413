.my-swal-popup {
    border-radius: 0 !important;
    /* border-top: 5px solid var(--custom-color);  */
}
.swal2-styled.swal2-confirm {
  width: 80px;
}
.swal2-styled.swal2-confirm:focus {
  box-shadow: none;
}
div:where(.swal2-container) .swal2-html-container {

  /* font-size:medium !important; */

}

.swal2-title {
  font-size: x-large !important;
  margin-bottom: 4px !important;
}
div:where(.swal2-container) .swal2-html-container {
  padding: 0em 1em !important;
}