.thumbInner {
    display: flex;
    min-width: 0;
    overflow: hidden;
}

.img {
    display: block;
    width: auto;
    height: 100%;
}