.form-check-inline {
    display: inline-block;
    margin: 0 2rem 0 0rem !important;
}
.form-check-input[type=checkbox] {
    border-radius: 0.25em;
    margin-top: 3px !important;
}
.text-underline{
    text-decoration: underline;
}
.form-check-input:checked {
    background-color: #12349F !important;
    border-color: #12349F !important;
}