.custom-checkbox {
    display: inherit;
    background-color: white;
    border-color: 1px solid #55C091 ;
    padding: 7px;
    border-radius: 4px;
    
  }
  
  .custom-checkbox input[type="checkbox"] {
    display: none; /* Hide the default checkbox */
  }
  
  .custom-checkbox input[type="checkbox"] + label {
    cursor: pointer;
  }
  
  /* Style for when the checkbox is checked */
  .custom-checkbox input[type="checkbox"]:checked + label {
    background-color: #66bb6a; /* Set a different background color when checked */
    color: white; /* Change text color when checked */
  }
  

  /* new newwwwww */
.checkbox-item01 input:checked ~ .checkmark {
  background-color: var(--colorLoginBtn) !important;
  border: 1px solid var(--colorLoginBtn) !important;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-item01 input:checked ~ .checkmark:after {
  display: block;   
}

/* Style the checkmark/indicator */
.checkbox-item01 .checkmark:after {
  left: 5px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.radio-item {
  display: inline-block;
  position: relative;
  padding: 0 6px;
  margin: 10px 0 0;
}

.radio-item input[type='radio'] {
  display: none;
}

.radio-item label {
  color: #000000;
  font-weight: normal;
}

.radio-item label:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 1.5px solid  var(--colorPrimary) !important;
  background-color: transparent;
}

.radio-item input[type=radio]:checked + label:after {
  border-radius: 11px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 9px;
  left: 4px;
  content: " ";
  display: block;
  background: var(--colorPrimary) !important;
}

.form-check {
  padding-left: 0 !important;
}