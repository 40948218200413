.box-chartBar {
    border: 1px solid #D1D3D4;
    border-radius: 10px;
    margin: 8px;
    padding: 15px;
    width: 100%;
    height: 100%;
}

@media only screen and (max-width : 1045px) {
    .box-chartBar {
        margin: 0px;
        padding: 6px;
    }
}

@media only screen and (max-width : 767px) {
    .box-chartBar {
        padding: 15px;
    }
}