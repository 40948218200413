/* .loading {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent !important;
} */
.hrhDgb {
    position: absolute !important;
    left: 0;
    top: 30px;
    width: 100%;
    height: 100%;
    z-index: 10000000001;
    text-align: center;
    background: #007bff00 !important;
    opacity: 1;
    visibility: visible;
    -webkit-transition: opacity 0.4s,visibility -0.3s linear 0.5s;
    transition: opacity 0.4s,visibility -0.3s linear 0.5s;
    max-height: 100%em;
}
.iwSUvz {
    margin-bottom: -32px !important;
    /* border-radius: 10%; */
}

.overlay {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999 !important;
  }
  
  .bxUxya {
    color: white !important;
}
.sk-fade-in-quarter-second.sk-spinner.ball-beat {
    // margin-top: 27px;
}

/* newwww test */

